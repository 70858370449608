import "twin.macro"

export const Banner = () => {
  return (
    <div tw="bg-primary text-white py-12">
      <dl tw="container flex text-center tracking-wider uppercase flex-wrap justify-center">
        <div tw="mx-12 mb-10 sm:mb-0 lg:mx-16">
          <dt tw="font-display text-4xl text-gold lg:text-5xl">Q2 2022</dt>
          <dd tw="font-semibold text-sm leading-6 mt-1">Geplanter Baubeginn</dd>
        </div>
        <div tw="mx-12 lg:mx-16">
          <dt tw="font-display text-4xl text-gold lg:text-5xl">Q2 2023</dt>
          <dd tw="font-semibold text-sm leading-6 mt-1">
            Geplante Bezugsfertigkeit
          </dd>
        </div>
      </dl>
    </div>
  )
}
