// @ts-ignore
import React from "react"
import { Story } from "@storybook/react"
import faker from "faker"
import { FullscreenGallery, FullscreenGalleryProps } from "./index"
import { Button } from "../../elements"
import { useDeclarativeState } from "../../hooks"

export default {
  title: "Components/FullscreenGallery",
  args: {
    sidebarTitle: "Sidebare Title",
    sidebarSubtitle: "Subtitle",
    Gallery: Array.from({ length: 9 }, () => ({
      title: faker.random.words(3),
      text: faker.lorem.paragraph(),
      Image: <img src="https://picsum.photos/1280/720" alt="" />,
    })),
  },
}

const Template: Story<FullscreenGalleryProps> = args => {
  const { sidebarTitle, sidebarSubtitle, Gallery } = args
  const [isActive, activate, deactivate] = useDeclarativeState(true)
  return (
    <>
      <Button onClick={activate}>Show Gallery</Button>
      <FullscreenGallery
        {...{ sidebarTitle, sidebarSubtitle, Gallery }}
        {...{ isActive, deactivate }}
      />
    </>
  )
}

export const DarkTheme = Template.bind({})
DarkTheme.args = {
  detailScreenTheme: "dark",
}

export const LightTheme = Template.bind({})
LightTheme.args = {
  detailScreenTheme: "light",
}
