import "twin.macro"
import { NavbarLogo, NavbarLogoProps } from "./navbar-logo"

export const Navbar = ({ Link }: NavbarProps) => {
  return (
    <div tw="py-8 height[85px]">
      <nav tw="fixed border-b top-0 inset-x-0 bg-white z-40">
        <div tw="container py-4 flex items-center justify-center">
          <NavbarLogo {...{ Link }} />
        </div>
      </nav>
    </div>
  )
}

export type NavbarProps = {
  Link?: NavbarLogoProps["Link"]
}
