import { useReducer } from "react"
import { theme } from "twin.macro"
import { FullscreenGalleryScreen, FullscreenGalleryColorTheme } from "./_types"

export const useFullscreenGalleryScreens = (
  detailScreenTheme: FullscreenGalleryColorTheme = "light"
) => {
  // screens and reducer are inside the hook, because bgColor is dynamic
  const screens = {
    slider: {
      current: "slider" as FullscreenGalleryScreen,
      ...colorThemes[detailScreenTheme],
    },
    grid: {
      current: "grid" as FullscreenGalleryScreen,
      ...colorThemes.light,
    },
  }

  const reducer = (state: State, action: Action) => {
    switch (action.type) {
      case "showGrid":
        return screens.grid
      case "showSlider":
        return screens.slider
      default:
        return state
    }
  }

  const [screen, dispatch] = useReducer(reducer, screens.slider)
  const showGrid = () => dispatch({ type: "showGrid" })
  const showSlider = () => dispatch({ type: "showSlider" })

  return [screen, showGrid, showSlider] as const
}

type State = {
  current: FullscreenGalleryScreen
  bgColor: string
  color: string
}

type Action = { type: "showSlider" } | { type: "showGrid" }

const colorThemes = {
  dark: {
    bgColor: theme`colors.primary`,
    color: theme`colors.white`,
  },
  light: {
    bgColor: theme`colors.gray.100`,
    color: theme`colors.gray.900`,
  },
}
