import "twin.macro"
import { AspectRatioImageWrapper } from "../../elements"
import { HeroLabel } from "./_hero-label"

export const Hero = ({ label, Image }: HeroProps) => {
  return (
    <header tw="relative">
      <AspectRatioImageWrapper tw="aspect-w-16 aspect-h-9 xl:(aspect-w-3 aspect-h-1)">
        {Image}
      </AspectRatioImageWrapper>
      <div tw="absolute top-0 left-0 w-full text-center">
        <span tw="bg-ci-green text-white inline-block px-12 sm:px-20 py-2 bg-opacity-70 uppercase font-medium tracking-wider text-sm sm:text-3xl">
          Bereits 85% vermarktet
        </span>
      </div>
      {label && <HeroLabel>{label}</HeroLabel>}
    </header>
  )
}

export type HeroProps = {
  label?: string
  Image?: JSX.Element
}
