/* eslint-disable react/jsx-props-no-spreading */
import "twin.macro"
import { Logo } from "../../elements"
import { FooterAddress, FooterAddressProps } from "./_footer-address"

export const Footer = ({ MenuItems }: FooterProps) => {
  return (
    <footer tw="text-center">
      <div tw="bg-primary text-white">
        <div tw="container py-16">
          <Logo tw="w-40 h-auto mx-auto" />
          <FooterAddress {...address1} tw="mt-24" />
          <FooterAddress {...address2} tw="mt-10" />
        </div>
      </div>
      <nav tw="container flex justify-center space-x-8 uppercase py-4 font-semibold text-gold tracking-wider">
        {MenuItems}
      </nav>
    </footer>
  )
}

const address1: FooterAddressObj = {
  title: "Anschrift des Projekts:",
  rows: ["Fitter Straße 19–21", "66663 Merzig / Hilbringen"],
}

const address2: FooterAddressObj = {
  title: `Bauträger: Immo Hirtengarten<br/>GmbH & CO. KG`,
  rows: ["Merchinger Straße 69", "66663 Merzig"],
}

export type FooterProps = {
  MenuItems: JSX.Element[]
}

type FooterAddressObj = Pick<FooterAddressProps, "title" | "rows">
