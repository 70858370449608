import "twin.macro"
import { AspectRatioImageWrapper, Title } from "../../elements"
import { LocationList } from "./_location-list"

export const Location = ({ MapImage }: LocationProps) => {
  return (
    <div tw="text-center container">
      <Title>
        Beste Nachbarschaft
        <br />
        in Bester Lage
      </Title>
      <div tw="max-w-2xl mx-auto">
        <AspectRatioImageWrapper tw="aspect-w-16 aspect-h-9 mt-6">
          {MapImage}
        </AspectRatioImageWrapper>
      </div>
      <p tw="mt-6 max-w-lg mx-auto">
        Im Herzen der Saar-Lor-Lux-Region liegt die pittoreske nordsaarländische
        Kreisstadt Merzig zwischen Saarbrücken und Trier (jeweils 50 Kilometer
        entfernt). Die Region ist bekannt für Lebensart, Genuss, das Miteinander
        von Stadt und Land sowie die Herzlichkeit ihrer Bewohner. Das
        wohlhabende Großherzogtum Luxemburg mit seinen kulinarischen und
        kulturellen Schätzen ist ebenfalls nur 30 Kilometer entfernt.
      </p>
      <LocationList tw="mt-6" />
    </div>
  )
}

export type LocationProps = {
  MapImage: JSX.Element
}
