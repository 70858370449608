import { Title } from "../../elements"
import "twin.macro"

export const Architecture = ({ Image }: ArchitectureProps) => {
  return (
    <div
      tw="relative w-full bg-gray-200
    "
    >
      <div
        tw="relative w-full pt-10 bg-gray-200
  container max-w-screen-xl! xl:px-0!"
      >
        <Title tw="text-center">Die Architektur</Title>
        <p tw="text-center">Intelligent, individuell, im Grünen.</p>
        <div tw="flex flex-col flex-1 py-10 sm:flex-row">
          <div tw="px-10 pt-5 pb-5 text-center sm:text-left sm:pl-0 sm:pr-10 sm:pt-10 sm:w-1/2">
            <p tw="pb-5">
              Das Quartier Hirtengarten bietet einen durchdachten Wohnungsmix
              von barrierefreien Zwei- bis Vierzimmerwohnungen für Menschen mit
              den unterschiedlichsten Wünschen. Entworfen vom Architekturbüro
              Bochem.Schmidt, lag das besondere Augenmerk auf Individualität und
              Eleganz. Jeder Grundriss ist einzigartig. Durch die Aufteilung der
              13 Wohnungen auf drei Gebäude wird das Ensemble aufgelockert. Es
              entsteht ein großzügiges Wohngefühl mit vielen
              Entfaltungsmöglichkeiten.
            </p>
            <p tw="pb-5">
              Die Bauweise ist durch ihre konsequente Durchgrünung in Form der
              Garten- und Rasenflächen zwischen den Gebäuden als auch die
              extensive Begrünung der Flachdachflächen sehr nachhaltig. Eine
              klare Formensprache und Bauhaus-Elemente geben dem Quartier ein
              elegantes Flair. Die grüne Außenanlage ist das verbindende Element
              zwischen den drei Gebäuden und gibt dem Quartier seinen
              persönlichen Charakter.
            </p>
            <p>
              Sonnenlauf und Licht sind ebenfalls maßgebende Faktoren der
              Architektur des Quartiers. Die durchdachte Ausrichtung der
              Wohnungen und Gebäude sowie der Einsatz von bodentiefen Fenstern
              ermöglichen lichtdurchflutete Ess- und Wohn- bereiche.
            </p>
          </div>
          <div tw="flex px-10 pt-5 pb-10 sm:pr-0 sm:pl-10 sm:pt-10 sm:w-1/2">
            {Image && Image}
          </div>
        </div>
      </div>
    </div>
  )
}

export type ArchitectureProps = {
  Image?: JSX.Element
}
