import "twin.macro"
import { Logo } from "../../elements"

export const NavbarLogo = ({ Link }: NavbarLogoProps) => {
  return Link ? (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link>
      <Logo textColor="currentColor" tw="w-20 h-auto" />
    </Link>
  ) : (
    <Logo textColor="currentColor" tw="w-20 h-auto mx-auto" />
  )
}

export type NavbarLogoProps = {
  menuIsOpen?: boolean
  Link?: React.FC
}
