import tw, { theme, styled } from "twin.macro"

// @ts-ignore
export const StyledFeature = styled.article`
  ${tw`px-2 py-6 text-center bg-gray-200`}
  /* First two elements */
  &:nth-child(-n + 2) {
    ${tw`pt-12`}
  }
  /* Last two elements */
  &:nth-last-child(-n + 2) {
    ${tw`pb-12`}
  }

  @media screen and (min-width: ${theme`screens.md`}) {
    ${tw`px-4 py-8`}
    /* First three elements */
    &:nth-child(-n + 3) {
      ${tw`pt-16`}
    }
    /* Last three elements */
    &:nth-last-child(-n + 3) {
      ${tw`pb-16`}
    }
  }
`
