import "twin.macro"
import { Emblem } from "../../../elements"
import { StyledFeature } from "./feature.styles"

export const Feature = ({ title, text }: FeatureProps) => {
  return (
    <StyledFeature>
      <Emblem tw="w-8 h-auto mx-auto" />
      <h3 tw="font-semibold uppercase tracking-wider mt-4">{title}</h3>
      <p tw="max-w-sm mx-auto">{text}</p>
    </StyledFeature>
  )
}

export type FeatureProps = {
  title: string
  text: string
}
