// @ts-ignore
import React from "react"
import { GlobalStyles as TwinMacroGlobalStyles } from "twin.macro"
import "./fonts/font-faces.css"

export const GlobalStyles = () => (
  <>
    <TwinMacroGlobalStyles />
  </>
)
