import "twin.macro"

export const MediaViewerDesktopSidebarHeader = ({
  subtitle,
  title,
}: MediaViewerDesktopSidebarHeaderProps) => {
  return (
    <header tw="mt-20">
      {subtitle && (
        <span tw="uppercase tracking-wider text-gray-500 block mb-2">
          {subtitle}
        </span>
      )}
      <h2 tw="text-3xl uppercase tracking-wider text-primary">{title}</h2>
    </header>
  )
}

export type MediaViewerDesktopSidebarHeaderProps = {
  subtitle?: string
  title: string
}
