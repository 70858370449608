/* eslint-disable react/jsx-props-no-spreading */
// @ts-ignore
import React, { useState } from "react"
import "twin.macro"
import { useForm } from "react-hook-form"
import { Emblem, Button, Title } from "../../elements"
import { Modal, ModalProps, useModal } from "../modal"
import {
  Input,
  Select,
  Textarea,
  Checkbox,
  FormElement,
} from "./_form-elements"

export const ContactForm = ({ PrivacyLabel }: ContactFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const modal = useModal()
  const { register, handleSubmit, reset } = useForm()

  const onSubmit = (data: any) => {
    setIsSubmitting(true)
    fetch(".netlify/functions/email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    })
      .then(response => {
        setIsSubmitting(false)
        // on success
        if (response.status === 200) {
          reset()
          modal.show(modalContents.success)
        }
        // on error
        else {
          modal.show(modalContents.error)
        }
        return response.json()
      })
      .then(result => {
        // eslint-disable-next-line no-console
        console.log(result)
      })
      .catch(error => {
        setIsSubmitting(false)
        // eslint-disable-next-line no-console
        console.log("error", error)
      })
  }
  return (
    <>
      <div tw="container max-w-2xl!">
        <Emblem tw="w-12 mx-auto" />
        <Title tw="text-center mt-4">
          Wir freuen uns
          <br />
          von Ihnen zu Hören
        </Title>
        <form
          tw="space-y-8 flex flex-col mt-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Select
            label="Anrede"
            options={["Herr", "Frau"]}
            {...register("salutation")}
          />
          <Input required label="Vorname" {...register("firstName")} />
          <Input required label="Nachname" {...register("lastName")} />
          <Input required label="Email" type="email" {...register("email")} />
          <Input required label="Telefon" type="tel" {...register("phone")} />
          <Textarea label="Ihre Nachricht" {...register("message")} />
          <Checkbox required label={PrivacyLabel} {...register("privacy")} />
          <Button disabled={isSubmitting} type="submit">
            {isSubmitting ? "Bitte warten..." : "Abschicken"}
          </Button>
        </form>
      </div>
      <Modal {...modal} />
    </>
  )
}

export type ContactFormProps = {
  PrivacyLabel: FormElement["label"]
}

const modalContents: { success: ModalContent; error: ModalContent } = {
  success: {
    title: "Nachricht gesendet, Vielen Dank!",
    text: "Wir werden uns schnellstmöglich bei Ihnen melden.",
  },
  error: {
    title: "Fehler beim Senden der Nachricht.",
    text: "Bitte versuchen Sie es später noch einmal.",
  },
}
type ModalContent = Pick<ModalProps, "title" | "text">
