import { StaticImage } from "gatsby-plugin-image"
import { ImageSeperator } from "../../components"

export const ArriveBanner = () => {
  return (
    <ImageSeperator
      text="Willkommen im neuen Zuhause"
      Image={WelcomeBannerImage}
    />
  )
}

const WelcomeBannerImage = <StaticImage src="../../images/arrive.jpg" alt="" />
