export const PlayIcon = ({ className }: PlayIconProps) => {
  return (
    <svg viewBox="0 0 512 512" className={className}>
      <title>Play</title>
      <path d="M96 448l320-192L96 64v384z" fill="currentColor" />
    </svg>
  )
}

export type PlayIconProps = {
  className?: string
}
