import { graphql, useStaticQuery } from "gatsby"
import "twin.macro"
import sortBy from "lodash/sortBy"
import { CondoList, CondoListProps } from "../../components"
import { SanityCondoPreview } from "./_types"
import { filterAndSanitizeCondos } from "./_helper"

export const GatsbyCondoList = () => {
  const results =
    useStaticQuery<GatsbyCondoListQueryResults>(GatsbyCondoListQuery)

  const { condos } = results.allSanityCondo

  const sortedCondos = sortBy(condos, "referenceNumber")

  // Order Condos after their refenceNumbers
  const condosBuildingA = filterAndSanitizeCondos(sortedCondos, "A.")
  const condosBuildingB = filterAndSanitizeCondos(sortedCondos, "B.")
  const condosBuildingC = filterAndSanitizeCondos(sortedCondos, "C.")

  // Build CondoLists with all available condos
  const condoListItems: CondoListProps["items"] = []

  if (condosBuildingA.length > 0)
    condoListItems.push({
      title: "Gebäude A",
      condos: condosBuildingA,
    })

  if (condosBuildingB.length > 0)
    condoListItems.push({
      title: "Gebäude B",
      condos: condosBuildingB,
    })

  if (condosBuildingC.length > 0)
    condoListItems.push({
      title: "Gebäude C",
      condos: condosBuildingC,
    })

  return (
    <div tw="container max-w-screen-xl! xl:px-0!">
      <CondoList items={condoListItems} />
    </div>
  )
}

type GatsbyCondoListQueryResults = {
  allSanityCondo: {
    condos: SanityCondoPreview[]
  }
}

const GatsbyCondoListQuery = graphql`
  query GatsbyCondoList {
    allSanityCondo(filter: { status: { ne: "sold" } }) {
      condos: nodes {
        referenceNumber
        space
        rooms
        status
        titleImage {
          asset {
            gatsbyImageData(width: 700)
          }
        }
      }
    }
  }
`
