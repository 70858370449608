import { useState, useEffect } from "react"
import "twin.macro"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Navigation,
  Pagination,
  Keyboard,
  Autoplay,
  Controller,
} from "swiper/core"
import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import { FullscreenGalleryProps } from "./fullscreen-gallery"
import { SetStateFN } from "../../types"

SwiperCore.use([Navigation, Pagination, Keyboard, Autoplay, Controller])

// TODO: Fix Mobile height
// maybe try a use FullscreenContentHeight() hook
// Or try if the y-padding effects the layout here
export const FullscreenGallerySlider = ({
  Gallery,
  index,
  autoplay,
  setIndex,
}: FullscreenGallerySliderProps) => {
  const [slider, setSlider] = useState<SwiperCore>()
  useEffect(() => {
    if (!slider) return
    if (autoplay) slider.autoplay.start()
    else slider.autoplay.stop()
  }, [autoplay, slider])
  /* Rerender when autoplay changes */
  return (
    <div tw="max-w-screen-2xl p-4 flex items-center w-full h-full">
      <Swiper
        loop
        onSwiper={swiper => setSlider(swiper)}
        onSlideChange={({ realIndex }) => setIndex(realIndex)}
        autoplay={autoplay}
        keyboard
        pagination={{
          type: "fraction",
          el: ".hero-gallery-fraction",
          renderFraction,
        }}
        navigation={{
          nextEl: ".hero-gallery-next",
          prevEl: ".hero-gallery-prev",
        }}
        spaceBetween={16}
        slidesPerView={1}
        initialSlide={index}
        style={{ height: "100%" }}
      >
        {Gallery.map((Item, key) => (
          <SwiperSlide style={{ height: "100%" }} key={key}>
            <div tw="flex justify-center items-center h-full">{Item.Image}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

const renderFraction = (currentClass: string, totalClass: string): string =>
  `<span class="${currentClass}"></span> von <span class="${totalClass}"></span>`

export type FullscreenGallerySliderProps = {
  Gallery: FullscreenGalleryProps["Gallery"]
  index: number
  setIndex: SetStateFN<number>
  autoplay: boolean
}
