// @ts-ignore
import React from "react"
import "twin.macro"
import { LabelWrapper } from "./_label-wrapper"
import { FormElement } from "./_types"
import { formElementStyles } from "./_form-element.styles"

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ name, label, type = "text", datalist, onChange, required }, ref) => {
    /* Renders list attribute in input only if defined */
    const datalistName = datalist ? `${name}-datalist` : undefined

    return (
      <>
        <LabelWrapper {...{ name, label, required }}>
          <input
            css={formElementStyles}
            type={type}
            name={name}
            id={name}
            list={datalistName}
            ref={ref}
            onChange={onChange}
            required={required}
          />
        </LabelWrapper>
        {/* Optional Datalist */}
        {datalist && (
          <datalist id={datalistName}>
            {datalist.map((value, key) => (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <option value={value} key={key} />
            ))}
          </datalist>
        )}
      </>
    )
  }
)

export type InputProps = FormElement<{
  type?: "text" | "tel" | "email"
  datalist?: string[]
}>
