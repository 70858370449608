import { GalleryPageModule } from "../../components"
import { GalleryItems, DecoratorImages } from "./gallery-items"

export const Gallery = ({ className }: GalleryProps) => {
  return (
    <GalleryPageModule
      Gallery={GalleryItems}
      DecorativeImages={DecoratorImages}
      className={className}
    />
  )
}

export type GalleryProps = {
  className?: string
}
