// @ts-ignore
import React from "react"
import "twin.macro"
import { PhotographIcon } from "@heroicons/react/solid"
import { Button, PlayIcon } from "../../../elements"
import { SetStateFN } from "../../../types"

export const FullscreenGalleryUIGrid = ({
  showSlider,
  setAutoplay,
}: FullscreenGalleryGridProps) => {
  // Function to start slider with autoplay set to tre
  const startWithAutoplay = () => {
    setAutoplay(true)
    showSlider()
  }

  return (
    <>
      {/* Show slider with autoplay */}
      <Button tw="ml-auto" onClick={startWithAutoplay}>
        <PlayIcon tw="w-4 h-auto" />
      </Button>
      {/* Show slider */}
      <Button tw="ml-auto" onClick={showSlider}>
        <PhotographIcon tw="w-4 h-auto" />
      </Button>
    </>
  )
}

export type FullscreenGalleryGridProps = {
  showSlider: () => void
  setAutoplay: SetStateFN<boolean>
}
