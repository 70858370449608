import { StaticImage } from "gatsby-plugin-image"
import { ImageSeperator } from "../../components"

export const LivingInGreenBanner = () => {
  return (
    <ImageSeperator text="Auf ins neue Zuhause" Image={WelcomeBannerImage} />
  )
}

const WelcomeBannerImage = (
  <StaticImage src="../../images/living-in-green.jpg" alt="" />
)
