/* eslint-disable import/no-cycle */
import "twin.macro"
import { motion, Variants } from "framer-motion"
import { XIcon } from "@heroicons/react/solid"
import { Logo, Button } from "../../elements"
import { MediaViewerProps } from "./media-viewer"

export const MediaViewerMobileHeader = ({
  deactivate,
}: MediaViewerMobileHeaderProps) => {
  return (
    <motion.header
      variants={animationVariants}
      tw="py-2 lg:hidden text-gray-900"
    >
      <div tw="flex items-center justify-between container">
        <Logo tw="w-24 h-auto" />
        <Button onClick={deactivate}>
          <span tw="sr-only">Schließen</span>
          <XIcon tw="w-6 h-auto" />
        </Button>
      </div>
    </motion.header>
  )
}

export type MediaViewerMobileHeaderProps = {
  deactivate: MediaViewerProps["deactivate"]
}

/*
 * We just need to add the variants here
 * (no animate, inital or exit props)
 * because the parent-element (media-viewer) has equal variants
 */
const animationVariants: Variants = {
  active: {
    opacity: 1,
    x: "0%",
    transition: {
      duration: 0.5,
    },
  },
  hidden: {
    x: "50%",
    opacity: 0,
  },
}
