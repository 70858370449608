// @ts-ignore
import React from "react"

export const Surface = ({ value }: SurfaceProps) => {
  const intl = new Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 2,
  })
  return (
    <>
      {intl.format(value)} m<sup>2</sup>
    </>
  )
}

export type SurfaceProps = {
  value: number
}
