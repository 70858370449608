import tw, { styled, theme } from "twin.macro"

export const MediaViewerUIWrapper = styled.div<MediaViewerUIWrapperProps>`
  // We need to use a dynamic color with fallback for mobile devices, because the screen color is dynamic.
  color: ${props => props.color || theme`colors.gray.900`};
  ${tw`fixed bottom-0 right-0 z-10 p-4`}
  // On larger screens it always shows up left, above a white screen
  // Padding is copied from sidebar, to fit it's width. 
  // The width gets determined in the parent component
  ${tw`lg:(text-gray-900 right-auto left-0 p-10 pr-6 bg-white)`}
  ${tw`xl:(p-16 pr-10)`}
`

export type MediaViewerUIWrapperProps = {
  color?: string
}
