import "twin.macro"
import { AzuryLogo } from "../../elements"

export const ContactPerson = () => {
  return (
    <div tw="text-center flex flex-col items-center container">
      <h2 tw="text-center text-2xl font-display uppercase tracking-wider mt-4">
        Für Rückfragen stehen
        <br />
        wir Ihnen gerne zur Verfügung
      </h2>

      <address tw="not-italic mt-3">
        <a href="tel:0049681410967594">+ 49 681 / 410 967 594</a>
        <br />
        <a
          tw="underline"
          href="mailto:anfrage@hirtengarten.de?subject=Anfrage%20Quartier%20Hirtengarten"
        >
          anfrage@hirtengarten.de
        </a>
        <br />
        www.hirtengarten.de
      </address>
      <h3 tw="font-semibold uppercase tracking-wider mt-8">
        Exklusivvermarktung durch
        <br />
        Azury Living GmbH
      </h3>
      <a target="_blank" rel="noopener noreferrer" href="https://azury.one">
        <AzuryLogo tw="w-32 h-auto mt-2" />
      </a>
    </div>
  )
}
