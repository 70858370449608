import React from "react"
import "twin.macro"
import { motion, Variants } from "framer-motion"
import { Logo } from "../../elements"

export const MediaViewerDesktopSidebar = ({
  Content,
  className,
}: MediaViewerDesktopSidebarProps) => {
  return (
    <div
      tw="hidden lg:(block bg-white h-full p-10 pr-6 pb-40 relative overflow-y-auto) xl:(p-16 pr-10 pb-40) text-gray-900"
      className={className}
    >
      <motion.div
        tw="overflow-hidden"
        variants={animationVariants.logo}
        initial="hidden"
        animate="active"
        exit="exit"
      >
        <Logo tw="w-40 h-auto" />
      </motion.div>
      <motion.div
        variants={animationVariants.content}
        initial="hidden"
        animate="active"
        exit="exit"
      >
        {Content}
      </motion.div>
    </div>
  )
}

export type MediaViewerDesktopSidebarProps = {
  Content: React.ReactNode
  className?: string
}

/*
 * We just need to add the variants here
 * (no animate, inital or exit props)
 * because the parent-element (media-viewer) has equal variants
 */
const animationVariants: { [key: string]: Variants } = {
  logo: {
    active: {
      opacity: 1,
      scale: 1,
      x: 0,
      height: "auto",
      transition: {
        duration: 0.25,
        delay: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      height: 0,
      scale: 0.5,
      x: -50,
    },
    exit: {
      x: -200,
      opacity: 0,
    },
  },
  content: {
    active: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: 0.6,
      },
    },
    hidden: {
      opacity: 0,
      y: -100,
    },
    exit: {
      x: -200,
      opacity: 0,
    },
  },
}
