import { Link } from "gatsby"
import React from "react"
import { Layout, LayoutProps } from "../../components"

export const GatsbyLayout = ({ children }: GatsbyLayoutProps) => {
  return (
    <Layout NavbarLogoLink={HomeLink} FooterMenuItems={FooterMenuItems}>
      {children}
    </Layout>
  )
}

const HomeLink: React.FC = ({ children }) => <Link to="/">{children}</Link>

const FooterMenuItems = [
  <Link key="imprint" to="/impressum">
    Impressum
  </Link>,
  <Link key="privacy" to="/datenschutz">
    Datenschutz
  </Link>,
]

export type GatsbyLayoutProps = {
  children: LayoutProps["children"]
}
