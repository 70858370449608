import "twin.macro"
import { DecorativeImageWrapper } from "./_decorative-image-wrapper"

export const DecorativeImageGrid = ({ Images }: DecorativeImageGridProps) => {
  const [FirstImage, SecondImage, ThirdImage, FourthImage] = Images
  return (
    <div tw="grid space-y-4">
      {/* Row 1 */}
      <div tw="flex h-80 space-x-4">
        <DecorativeImageWrapper tw="w-3/5">{FirstImage}</DecorativeImageWrapper>
        <DecorativeImageWrapper tw="w-2/5">
          {SecondImage}
        </DecorativeImageWrapper>
      </div>
      {/* Row 1 */}
      <div tw="flex h-80 space-x-4">
        <DecorativeImageWrapper tw="w-2/5">{ThirdImage}</DecorativeImageWrapper>
        <DecorativeImageWrapper tw="w-3/5">
          {FourthImage}
        </DecorativeImageWrapper>
      </div>
    </div>
  )
}

export type DecorativeImageGridProps = {
  // Four Images
  Images: [JSX.Element, JSX.Element, JSX.Element, JSX.Element]
}
