// @ts-ignore
import React from "react"

export const Price = ({ value }: PriceProps) => {
  const currency = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  })
  return <>{currency.format(value)}</>
}

export type PriceProps = {
  value: number
}
