import "twin.macro"
import {
  FullscreenGalleryUIGrid,
  FullscreenGalleryGridProps,
} from "./_fullscreen-gallery-ui-grid"
import {
  FullscreenGalleryUISlider,
  FullscreenGalleryUISliderProps,
} from "./_fullscreen-gallery-ui-slider"
import { FullscreenGalleryScreen } from "../_types"

export const FullscreenGalleryUI = ({
  screen,
  autoplay,
  toggleAutoplay,
  showGrid,
  setAutoplay,
  showSlider,
}: FullscreenGalleryUIProps) => {
  return (
    <div tw="flex items-center justify-end space-x-4">
      {screen === "grid" ? (
        <FullscreenGalleryUIGrid {...{ showSlider, setAutoplay }} />
      ) : (
        <FullscreenGalleryUISlider
          {...{ autoplay, toggleAutoplay, showGrid }}
        />
      )}
    </div>
  )
}

export type FullscreenGalleryUIProps = {
  screen: FullscreenGalleryScreen
} & FullscreenGalleryGridProps &
  FullscreenGalleryUISliderProps
