import React from "react"
import "twin.macro"

export const Table = ({ items, className }: TableProps) => {
  return (
    <table className={className}>
      <tbody>
        {items.map(item => {
          return (
            <tr
              key={item.label}
              tw="border-b border-gold text-gray-800 mt-3 mb-1 flex flex-row justify-between items-end px-2"
            >
              <td tw="uppercase font-bold inline tracking-wider text-gray-600">
                {item.label}
              </td>
              <td tw="text-right text-xl">{item.value}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export type TableProps = {
  className?: string
  items: {
    label: string
    value: React.ReactNode
  }[]
}
