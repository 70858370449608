import { useState } from "react"

/**
 * For useState behavior with an additional toggle function
 * @param initialState {boolean}
 * @returns [state, toggle, setState]
 */
export const useToggle = (initialState = false) => {
  const [isActive, setIsActive] = useState(initialState)
  const toggle = () => setIsActive(prev => !prev)

  return [isActive, toggle, setIsActive] as const
}
