import {
  MediaViewerWrapper,
  MediaViewerWrapperProps,
} from "./_media-viewer-wrapper"
import {
  MediaViewer as MediaViewerComponent,
  MediaViewerProps as MediaViewerComponentProps,
} from "./media-viewer"

export * from "./media-viewer-desktop-sidebar-header"

/*
 * We "override" the mediaViewer component here
 * to be wrapped by MediaViewer Wrapper
 * this is necessary so that mediaViewer can use useBodyScrollLock() correctly
 */
export const MediaViewer = ({
  isActive,
  deactivate,
  children,
  contentBGColor,
  contentColor,
  SidebarContent,
  UI,
}: MediaViewerProps) => (
  <MediaViewerWrapper {...{ isActive }}>
    <MediaViewerComponent
      {...{ deactivate, contentBGColor, contentColor, SidebarContent, UI }}
    >
      {children}
    </MediaViewerComponent>
  </MediaViewerWrapper>
)

export type MediaViewerProps = MediaViewerComponentProps &
  MediaViewerWrapperProps
