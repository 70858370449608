import "twin.macro"
import { useDeclarativeState } from "../../hooks"
import { Button } from "../../elements"
import {
  FullscreenGallery,
  FullscreenGalleryProps,
} from "../fullscreen-gallery"
import {
  DecorativeImageGrid,
  DecorativeImageGridProps,
} from "../decorative-image-grid"

export const GalleryPageModule = ({
  Gallery,
  DecorativeImages,
  className,
}: GalleryPageModuleProps) => {
  const [isActive, activate, deactivate] = useDeclarativeState(false)
  return (
    <div className={className}>
      <div tw="container text-right max-w-screen-xl!">
        <DecorativeImageGrid Images={DecorativeImages} />
        <Button tw="mt-4 ml-auto" onClick={activate}>
          Bildergalerie
        </Button>
      </div>

      <FullscreenGallery {...{ isActive, deactivate }} Gallery={Gallery} />
    </div>
  )
}

export type GalleryPageModuleProps = {
  DecorativeImages: DecorativeImageGridProps["Images"]
  Gallery: FullscreenGalleryProps["Gallery"]
  className?: string
}
