import tw, { styled } from "twin.macro"

export const ImageWithOverlayContent = styled.div`
  ${[
    tw`flex flex-col items-center justify-center`,
    tw`w-3/4 max-w-lg p-4`,
    tw`text-center text-white uppercase font-display`,
    tw`bg-ci-green bg-opacity-80`,
  ]}
`
