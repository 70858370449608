import { theme } from "twin.macro"

export const Emblem = ({ className, color }: EmblemProps) => {
  const fill = color ?? theme`colors.gold`
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48.7 46.6"
      className={className}
    >
      <title>Quartier Hirtengarten</title>
      <path
        id="a"
        d="M0 30c8 2 12 5 18 10-4-12-9-18-18-23v13zm1-17c8 4 14 10 19 17l-1-4c-3-7-5-13-9-18l-9 5zm48 17c-8 2-13 5-19 10 5-12 10-18 19-23v13zm-2-17c-7 4-13 10-18 17l1-4c2-7 5-13 9-18l8 5zM35 6c-5 6-8 14-11 21 9 26-9 26 0 0-2-7-5-15-10-21l10-6 11 6zm14 28a37 37 0 00-19 13h14c2 0 5-3 5-6v-7zM0 34a37 37 0 0119 13H5c-3 0-5-3-5-6v-7z"
        fill={fill}
      />
    </svg>
  )
}

export type EmblemProps = {
  className?: string
  color?: string
}
