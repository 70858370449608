// @ts-ignore
import React from "react"
import "twin.macro"
import { CondoGrid, CondoPreview } from "./_condo-grid"
import { Title, StatusBadge } from "../../elements"

export const CondoList = ({ items }: CondoListProps) => {
  return (
    <section>
      <header tw="text-center">
        <Title>Die Wohnungen</Title>
        <p tw="max-w-sm mx-auto mt-8">
          Reservieren Sie Ihre Traumwohnung oder lassen Sie sich auf unsere
          Warteliste setzen.
        </p>
        <p tw="mt-12 inline-flex space-x-8">
          <StatusBadge status="open" />
          <StatusBadge status="reserved" />
        </p>
      </header>
      <div tw="grid gap-16 mt-16 lg:mt-24">
        {items.map(item => (
          <CondoGrid key={item.title} title={item.title} condos={item.condos} />
        ))}
      </div>
    </section>
  )
}

export type CondoListProps = {
  items: {
    title: string
    condos: CondoPreview[]
  }[]
}
