import React from "react"
import "twin.macro"

export const HeroLabel = ({ children }: HeroLabelProps) => {
  return (
    <div tw="absolute bottom-0 inset-x-0 w-full text-center">
      <span tw="bg-ci-green text-white inline-block px-12 sm:px-20 py-2 bg-opacity-70 uppercase font-medium tracking-wider">
        {children}
      </span>
    </div>
  )
}

export type HeroLabelProps = {
  children: React.ReactNode
}
