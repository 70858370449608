import { useState } from "react"

/**
 * useState with more declarative state-action-functions
 * @returns [state, setStateToTrue(), setStateToFalse(), setState()]
 */
export const useDeclarativeState = (initialState = false) => {
  const [isTrue, setIsTrue] = useState(initialState)

  const setToTrue = () => setIsTrue(true)
  const setToFalse = () => setIsTrue(false)

  return [isTrue, setToTrue, setToFalse, setIsTrue] as const
}
