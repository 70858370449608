// @ts-ignore
import React from "react"
import "twin.macro"
import { Title } from "../../elements"
import { Feature, FeatureProps } from "./_feature"

export const FeatureList = ({ features }: FeatureListProps) => {
  return (
    <>
      <div tw="container">
        <Title tw="text-center">Die Vorzüge</Title>
        <p tw="text-center mt-4">
          Entdecken Sie die zahlreichen Vorzüge, die das Quartier Hirtengarten
          zu bieten hat.
        </p>
      </div>
      <div tw="bg-gray-200 mt-8 md:mt-16">
        <div tw="container max-w-screen-xl! xl:px-0!">
          <div tw="bg-gold gap-px grid grid-cols-2 md:grid-cols-3">
            {features.map(({ title, text }, key) => (
              <Feature key={key} {...{ title, text }} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export type FeatureListProps = {
  features: FeatureProps[]
}
