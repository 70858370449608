import { AnimatePresence } from "framer-motion"
import React from "react"

export const MediaViewerWrapper = ({
  isActive,
  children,
}: MediaViewerWrapperProps) => {
  return <AnimatePresence>{isActive && children}</AnimatePresence>
}

export type MediaViewerWrapperProps = {
  isActive: boolean
  children: React.ReactNode
}
