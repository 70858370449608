import tw, { styled } from "twin.macro"

const EmptyButton = styled.button<ButtonProps>([
  tw`inline-flex justify-center px-6 py-3`,
  tw`text-base font-medium`,
  tw`transition-colors`,
  tw`hover:bg-primary-600`,
  tw`focus:(outline-none ring-2 ring-offset-2 ring-primary)`,
  tw`active:(bg-primary-400)`,
])

export const Button = styled(EmptyButton)<ButtonProps>([
  tw`text-white bg-primary`,
  tw`border border-transparent rounded-md shadow-sm`,
])

export const ButtonOutline = styled(EmptyButton)<ButtonProps>([
  tw`font-bold tracking-wider text-gray-700 uppercase `,
  tw`border-2 shadow-sm border-gold`,
  tw`hover:bg-primary hover:text-white`,
])

export type ButtonProps = {
  to?: string
  href?: string
  target?: string
  rel?: string
  title?: string
}
