// @ts-ignore
import React from "react"
import "twin.macro"
import { FormElement } from "./_types"
import { formCheckboxStyles } from "./_form-element.styles"

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, label, onChange, required }, ref) => {
    return (
      <label tw="flex sm:items-center" htmlFor="privacy">
        <input
          css={formCheckboxStyles}
          tw="mr-2 mt-1 sm:mt-0"
          type="checkbox"
          name={name}
          id={name}
          ref={ref}
          onChange={onChange}
          required={required}
        />
        {label}
      </label>
    )
  }
)

export type CheckboxProps = FormElement
