import React from "react"
import "twin.macro"
import {
  ImageWithOverlay,
  ImageWithOverlayContent,
} from "../image-with-overlay"
import { Surface, StatusBadge } from "../../elements"
import { CondoStatus } from "../../types"

export const CondoGrid = ({ condos, title }: CondoGridProps) => {
  return (
    <div>
      <h2 tw="uppercase tracking-wider text-center font-semibold text-gray-600 text-lg md:text-2xl">
        {title}
      </h2>
      <div tw="grid grid-cols-2 gap-8 mt-6">
        {condos.map((condo, key) => (
          <condo.Link key={key}>
            <ImageWithOverlay Image={condo.Image}>
              <ImageWithOverlayContent>
                <h3 tw="text-xl sm:text-2xl md:text-5xl">
                  {condo.referenceNumber}
                </h3>
                <p tw="font-sans text-xs font-bold tracking-wider text-gray-100 mt-2 sm:text-lg md:text-2xl">
                  {condo.rooms} Zimmer
                  <br />
                  <Surface value={condo.space} />
                  <br />
                  <StatusBadge tw="mt-2" status={condo.status} hideText />
                </p>
              </ImageWithOverlayContent>
            </ImageWithOverlay>
          </condo.Link>
        ))}
      </div>
    </div>
  )
}

type CondoGridProps = {
  title: string
  condos: CondoPreview[]
}

export type CondoPreview = {
  referenceNumber: string
  rooms: number
  Image: JSX.Element
  space: number
  Link: React.FC
  status: CondoStatus
}
