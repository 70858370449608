import "twin.macro"

export const LocationList = ({ className }: LocationListProps) => {
  return (
    <dl tw="flex flex-col space-y-6" className={className}>
      {locationPlaces.map(location => (
        <div tw="flex flex-col-reverse items-center" key={location.title}>
          <dt tw="uppercase tracking-wider font-semibold">{location.title}</dt>
          <dd tw="font-display flex items-center before:(content w-4 h-px bg-gold) after:(content w-4 h-px bg-gold)">
            <span tw="inline-block px-4">{location.distance}</span>
          </dd>
        </div>
      ))}
    </dl>
  )
}

const locationPlaces = [
  {
    title: "Einkaufen",
    distance: "1.500 m",
  },
  {
    title: "Autobahn",
    distance: "1.200 m",
  },
  {
    title: "Bahnhof",
    distance: "2.200 m",
  },
  {
    title: "Merzig Innenstadt",
    distance: "2.200 m",
  },
  {
    title: "Saarlouis",
    distance: "20 km",
  },
]

export type LocationListProps = {
  className?: string
}
