import React from "react"
import "twin.macro"
import { LabelWrapper } from "./_label-wrapper"
import { FormElement } from "./_types"
import { formElementStyles } from "./_form-element.styles"

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ label, name, options, onChange, required }, ref) => {
    return (
      <LabelWrapper {...{ label, name, required }}>
        <select
          onChange={onChange}
          css={formElementStyles}
          name={name}
          id={name}
          ref={ref}
          required={required}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <option value="" />
          {options.map((option, key) => (
            <option key={key} value={option}>
              {option}
            </option>
          ))}
        </select>
      </LabelWrapper>
    )
  }
)

export type SelectProps = FormElement<{
  options: string[]
}>
