/* eslint-disable @typescript-eslint/ban-types */
import { useEffect } from "react"

/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export const useKeyPress = (key: string, action: Function) => {
  useEffect(() => {
    // Keyboard event-handler
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === key) action()
    }
    // Add and remove keyup-listener with our event-handler
    window.addEventListener("keyup", handleKeyUp)
    return () => window.removeEventListener("keyup", handleKeyUp)
  }, [action, key])
}
