import "twin.macro"
import { motion, Variants } from "framer-motion"
import { Button } from "../../elements"
import { useKeyPress, useOnClickOutside } from "../../hooks"

/*
 * Seperated the content into it's own component for the
 * "use-key-press" and "use-on-click-outside" add/removeEventListeners to work.
 */
export const ModalAnimatedContent = ({
  title,
  text,
  hide,
}: ModalAnimatedContentProps) => {
  // Close on ESC keypress
  useKeyPress("Escape", hide)
  // Close on click outside the white-box
  const outsideClickRef = useOnClickOutside(hide)

  return (
    <motion.div
      tw="fixed inset-0 w-full h-screen bg-gray-900 bg-opacity-80 flex items-center justify-center"
      variants={animationVarians.container}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <motion.div
        variants={animationVarians.modal}
        tw="bg-white rounded-xl shadow-xl overflow-hidden text-center"
        ref={outsideClickRef}
      >
        <div tw="p-6">
          <h2 tw="font-semibold text-xl">{title}</h2>
          <p>{text}</p>
        </div>
        <div tw="px-6 py-3 bg-gray-50">
          <Button onClick={hide}>Okay</Button>
        </div>
      </motion.div>
    </motion.div>
  )
}

export type ModalAnimatedContentProps = {
  title: string
  text: string
  hide: () => void
}

const animationVarians: { container: Variants; modal: Variants } = {
  container: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  },
  modal: {
    hidden: {
      opacity: 0,
      y: "-25%",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25,
      },
    },
  },
}
