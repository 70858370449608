import React from "react"
import "twin.macro"
import { FormElement } from "./_types"

export const LabelWrapper = ({
  name,
  label,
  children,
  required,
}: LabelWrapperProps) => {
  return (
    <label htmlFor={name}>
      <span tw="uppercase tracking-wider text-sm font-medium mb-3 block">
        {label}
        {required && <sup tw="font-bold">*</sup>}
      </span>
      {children}
    </label>
  )
}

export type LabelWrapperProps = {
  name: FormElement["name"]
  label: FormElement["label"]
  required?: FormElement["required"]
  children: React.ReactNode
}
