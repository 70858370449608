import "twin.macro"
import { FullscreenGalleryProps } from "./fullscreen-gallery"
import { FullscreenGalleryGridButton } from "./_fullscreen-gallery-grid-button"

export const FullscreenGalleryGrid = ({
  Gallery,
  showSlider,
  setIndex,
}: FullscreenGalleryGridProps) => {
  const openSliderWithIndex = (index: number) => {
    setIndex(index)
    showSlider()
  }

  return (
    <div tw="p-4 grid grid-cols-2 gap-4 lg:(p-16 pb-32 grid-cols-3)">
      {Gallery.map((Item, key) => (
        <FullscreenGalleryGridButton
          onClick={() => openSliderWithIndex(key)}
          onMouseOver={() => setIndex(key)}
          key={key}
        >
          {Item.Image}
        </FullscreenGalleryGridButton>
      ))}
    </div>
  )
}

export type FullscreenGalleryGridProps = {
  Gallery: FullscreenGalleryProps["Gallery"]
  setIndex: (index: number) => void
  showSlider: () => void
}
