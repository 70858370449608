import tw from "twin.macro"
import { CondoStatus } from "../../types"

export const StatusBadge = ({
  status,
  hideText = false,
  className,
}: StatusBadgeProps) => {
  // Since there are only two statuses, we can use a ternary operator
  const statusLabel = status === "open" ? "Verfügbar" : "Warteliste"

  // Dynamic icon styles
  const styles = {
    element: [tw`inline-flex items-center`],
    icon: [
      tw`inline-block w-6 h-auto`,
      !hideText && tw`mr-1`,
      status === "open" && tw`text-green-500`,
      status === "reserved" && tw`text-yellow-400`,
    ],
    label: [hideText && tw`sr-only`],
  }

  return (
    <span css={styles.element} className={className}>
      <svg viewBox="0 0 60 60" css={styles.icon}>
        <circle fill="currentColor" cx="30" cy="30" r="20" />
      </svg>
      <span css={styles.label}>{statusLabel}</span>
    </span>
  )
}

export type StatusBadgeProps = {
  status: CondoStatus
  hideText?: boolean
  className?: string
}
