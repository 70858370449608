export const AzuryLogo = ({ className }: AzuryLogoProps) => {
  return (
    <svg {...{ className }} viewBox="0 0 828 496.5">
      <style dangerouslySetInnerHTML={{ __html: ".st1{fill:#0f1428}" }} />
      <path
        d="m731.56 333.33-36.19 41.94-35.84-41.94h-26.96l47.71 54.01v31.22h21.75v-31.19l48.18-54.04zM136.88 346.77l22.78 39.48h-46l23.22-39.48zm-6.72-13.44-52.27 85.24h16.76l12.45-21.16h59l12.21 21.16h24.59l-51.79-85.24h-20.95zM526.79 344.37v30.71h41.26c16.25 0 22.14-6.87 22.14-15.35s-5.89-15.36-22.14-15.36h-41.26zm60.29 39.8 27.6 34.4H587.8l-24.09-32.43h-36.92v32.43h-20.14v-85.24h66.83c29.28 0 37.38 14.87 37.33 26.4 0 11.76-9.12 20.79-23.73 24.44M321.02 418.56h-98.24v-6.35l65.64-67.63h-60.51v-11.25h91.75v6.35l-65.64 67.63h67zM462.03 377.41c0 30.09-23.53 43.05-52.55 43.05-29.02 0-52.54-12.96-52.55-43.06v-44.08h22.13v44.08c0 22.95 14.47 31.34 32.9 31.34s34.74-8.4 34.75-31.36l.01-44.07h15.32v44.1z"
        className="st1"
      />
      <linearGradient
        id="SVGID_1_"
        x1="486.122"
        x2="415.064"
        y1="630.564"
        y2="630.564"
        gradientTransform="translate(0 -508.614)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} style={{ stopColor: "#0f1428" }} />
        <stop offset=".44" style={{ stopColor: "#1b2445" }} />
        <stop offset=".5" style={{ stopColor: "#1e274b" }} />
        <stop offset=".62" style={{ stopColor: "#262f52" }} />
        <stop offset=".81" style={{ stopColor: "#3b4968" }} />
        <stop offset={1} style={{ stopColor: "#556580" }} />
      </linearGradient>
      <path
        d="M414.01 77.6v36.3c0 31 23 42.7 43.2 52.4 15.9-7.6 30-14 30-27.6 0-9.4-5.2-16-13.4-21.5l-59.8-39.6z"
        style={{ fill: "url(#SVGID_1_)" }}
      />
      <linearGradient
        id="SVGID_00000179641095627732096280000017179372303535269565_"
        x1="414.012"
        x2="487.212"
        y1="705.464"
        y2="705.464"
        gradientTransform="translate(0 -508.614)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} style={{ stopColor: "#0f1428" }} />
        <stop offset=".44" style={{ stopColor: "#1b2445" }} />
        <stop offset=".5" style={{ stopColor: "#1e274b" }} />
        <stop offset=".62" style={{ stopColor: "#262f52" }} />
        <stop offset=".81" style={{ stopColor: "#3b4968" }} />
        <stop offset={1} style={{ stopColor: "#556580" }} />
      </linearGradient>
      <path
        d="M414.01 218.7V255l59.8-39.6c8.3-5.5 13.4-12.1 13.4-21.5v-55.2c0 30.8-73.2 24.8-73.2 80"
        style={{
          fill: "url(#SVGID_00000179641095627732096280000017179372303535269565_)",
        }}
      />
      <linearGradient
        id="SVGID_00000063597105422587782990000001660018612492263355_"
        x1="780.207"
        x2="709.196"
        y1="-555.774"
        y2="-555.774"
        gradientTransform="rotate(180 561.11 -172.562)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} style={{ stopColor: "#0f1428" }} />
        <stop offset=".44" style={{ stopColor: "#1b2445" }} />
        <stop offset=".5" style={{ stopColor: "#1e274b" }} />
        <stop offset=".62" style={{ stopColor: "#262f52" }} />
        <stop offset=".81" style={{ stopColor: "#3b4968" }} />
        <stop offset={1} style={{ stopColor: "#556580" }} />
      </linearGradient>
      <path
        d="M414.01 255v-36.3c0-31-23-42.7-43.2-52.4-15.9 7.6-30 14-30 27.6 0 9.4 5.2 16 13.4 21.5l59.8 39.6z"
        style={{
          fill: "url(#SVGID_00000063597105422587782990000001660018612492263355_)",
        }}
      />
      <linearGradient
        id="SVGID_00000000220171695921674090000007927975718838916482_"
        x1="708.208"
        x2="781.408"
        y1="-480.874"
        y2="-480.874"
        gradientTransform="rotate(180 561.11 -172.562)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} style={{ stopColor: "#0f1428" }} />
        <stop offset=".44" style={{ stopColor: "#1b2445" }} />
        <stop offset=".5" style={{ stopColor: "#1e274b" }} />
        <stop offset=".62" style={{ stopColor: "#262f52" }} />
        <stop offset=".81" style={{ stopColor: "#3b4968" }} />
        <stop offset={1} style={{ stopColor: "#556580" }} />
      </linearGradient>
      <path
        d="M414.01 113.9V77.6l-59.8 39.6c-8.3 5.5-13.4 12.1-13.4 21.5v55.2c0-30.8 73.2-24.7 73.2-80"
        style={{
          fill: "url(#SVGID_00000000220171695921674090000007927975718838916482_)",
        }}
      />
    </svg>
  )
}

export type AzuryLogoProps = {
  className?: string
}
