import React from "react"
import "twin.macro"
import {
  ImageWithOverlay,
  ImageWithOverlayContent,
} from "../image-with-overlay"
import { Emblem } from "../../elements"

export const ImageSeperator = ({ Image, text }: ImageSeperatorProps) => {
  return (
    <ImageWithOverlay {...{ Image }}>
      <ImageWithOverlayContent tw="text-2xl md:text-4xl">
        <span>{text}</span>
        <Emblem tw="w-10 h-auto mt-4" />
      </ImageWithOverlayContent>
    </ImageWithOverlay>
  )
}

export type ImageSeperatorProps = {
  Image: JSX.Element
  text: React.ReactNode
}
