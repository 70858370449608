import * as React from "react"
import "twin.macro"
import { GlobalStyles } from "../../styles"
import { Footer, FooterProps } from "../footer"
import { Navbar, NavbarProps } from "../navbar"

export const Layout = ({
  children,
  FooterMenuItems,
  NavbarLogoLink,
}: LayoutProps) => {
  return (
    <>
      <GlobalStyles />
      <Navbar Link={NavbarLogoLink} />
      <main>{children}</main>
      <Footer MenuItems={FooterMenuItems} />
    </>
  )
}

export type LayoutProps = {
  children: React.ReactNode
  FooterMenuItems: FooterProps["MenuItems"]
  NavbarLogoLink?: NavbarProps["Link"]
}
