// @ts-ignore
import React from "react"
import "twin.macro"
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PauseIcon,
  ViewGridIcon,
} from "@heroicons/react/solid"
import { Button, PlayIcon } from "../../../elements"
import { FullscreenGalleryUIButton } from "./_fullscreen-gallery-ui-button"

export const FullscreenGalleryUISlider = ({
  autoplay,
  toggleAutoplay,
  showGrid,
}: FullscreenGalleryUISliderProps) => {
  return (
    <>
      <div tw="mr-auto lg:(flex items-center space-x-4)">
        {/* Previous slide (hidden on mobile) */}
        <FullscreenGalleryUIButton
          tw="hidden! lg:inline-block!"
          className="hero-gallery-prev"
        >
          <span tw="sr-only">Previous Slide</span>
          <ChevronLeftIcon tw="w-4 h-auto" />
        </FullscreenGalleryUIButton>
        {/* Fraction */}
        <div>
          <span
            tw="block font-mono text-xs xl:text-base"
            className="hero-gallery-fraction"
          />
        </div>
        {/* Next Slide (hidden on mobike) */}
        <FullscreenGalleryUIButton
          tw="hidden! lg:inline-block!"
          className="hero-gallery-next"
        >
          <span tw="sr-only">Next Slider</span>
          <ChevronRightIcon tw="w-4 h-auto" />
        </FullscreenGalleryUIButton>
      </div>

      {/* Toggle Autoplay */}
      <Button onClick={toggleAutoplay}>
        {autoplay ? (
          <PauseIcon tw="w-4 h-auto" />
        ) : (
          <PlayIcon tw="w-4 h-auto" />
        )}
      </Button>
      {/* Show Grid */}
      <Button onClick={showGrid}>
        <ViewGridIcon tw="w-4 h-auto" />
      </Button>
    </>
  )
}

export type FullscreenGalleryUISliderProps = {
  autoplay: boolean
  toggleAutoplay: () => void
  showGrid: () => void
}
