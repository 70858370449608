import React from "react"
import "twin.macro"
import { AspectRatioImageWrapper } from "../../elements"

export const ImageWithOverlay = ({
  children,
  Image,
}: ImageWithOverlayProps) => {
  return (
    <section tw="relative py-8 lg:py-10 max-w-screen-xl mx-auto">
      {/* Background Image */}
      <AspectRatioImageWrapper tw="aspect-w-16 aspect-h-9">
        {Image}
      </AspectRatioImageWrapper>
      {/* Content */}
      <div tw="absolute inset-0 w-full h-full flex justify-center items-stretch">
        {children}
      </div>
    </section>
  )
}

export type ImageWithOverlayProps = {
  children: React.ReactNode
  Image: JSX.Element
}
