import tw, { styled } from "twin.macro"

export const FullscreenGalleryGridButton = styled.button`
  overflow: hidden;
  > * {
    ${tw`transition-transform duration-300 ease-in-out`}
  }
  &:hover {
    > * {
      ${tw`transform scale-110`}
    }
  }
`
