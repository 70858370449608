import { StaticImage } from "gatsby-plugin-image"
import { ImageSeperator } from "../../components"

export const WelcomeBanner = () => {
  return (
    <ImageSeperator
      text="Willkommen im neuen Zuhause"
      Image={WelcomeBannerImage}
    />
  )
}

const WelcomeBannerImage = (
  <StaticImage src="../../images/welcome-home.jpg" alt="" />
)
