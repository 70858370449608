import React from "react"
import "twin.macro"

export const FooterAddress = ({
  title,
  rows,
  className,
}: FooterAddressProps) => {
  return (
    <address tw="not-italic" className={className}>
      <span
        tw="font-semibold uppercase  tracking-wider"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {rows.map((row, key) => (
        <React.Fragment key={key}>
          <br />
          <span dangerouslySetInnerHTML={{ __html: row }} />
        </React.Fragment>
      ))}
    </address>
  )
}

export type FooterAddressProps = {
  className?: string
  title: string
  rows: string[]
}
