// @ts-ignore
import React, { useState } from "react"
import "twin.macro"
import { MediaViewer, MediaViewerProps } from "../media-viewer"
import { useKeyPress, useToggle } from "../../hooks"
import { FullscreenGalleryColorTheme } from "./_types"
import { useFullscreenGalleryScreens } from "./_use-fullscreen-gallery-screens"
import { FullscreenGalleryGrid } from "./_fullscreen-gallery-grid"
import { FullscreenGallerySlider } from "./_fullscreen-gallery-slider"
import { FullscreenGallerySidebarContent } from "./_fullscreen-gallery-sidebar-content"
import { FullscreenGalleryUI } from "./_fullscreen-gallery-ui"

export const FullscreenGallery = ({
  Gallery,
  isActive,
  deactivate,
  sidebarTitle,
  sidebarSubtitle,
  detailScreenTheme,
}: FullscreenGalleryProps) => {
  const [screen, showGridDefault, showSlider] =
    useFullscreenGalleryScreens(detailScreenTheme)
  const [index, setIndex] = useState(0)
  const [autoplay, toggleAutoplay, setAutoplay] = useToggle(false)

  // "Override deactivation, to also stop autoplay"
  const closeGallery = () => {
    deactivate()
    setAutoplay(false)
  }

  // Hide on ESC Press
  useKeyPress("Escape", closeGallery)

  // Override showGrid to always stop autoplay when called
  const showGrid = () => {
    setAutoplay(false)
    showGridDefault()
  }

  return (
    <MediaViewer
      UI={
        <FullscreenGalleryUI
          screen={screen.current}
          {...{
            autoplay,
            setAutoplay,
            toggleAutoplay,
            showGrid,
            showSlider,
          }}
        />
      }
      SidebarContent={
        <FullscreenGallerySidebarContent
          title={sidebarTitle}
          subtitle={sidebarSubtitle}
          {...{ Gallery, index }}
        />
      }
      contentBGColor={screen.bgColor}
      contentColor={screen.color}
      deactivate={closeGallery}
      {...{ isActive }}
    >
      {screen.current === "grid" ? (
        <FullscreenGalleryGrid {...{ Gallery, showSlider, setIndex }} />
      ) : (
        <FullscreenGallerySlider {...{ Gallery, index, setIndex, autoplay }} />
      )}
    </MediaViewer>
  )
}

export type FullscreenGalleryProps = {
  sidebarTitle?: string
  sidebarSubtitle?: string
  detailScreenTheme?: FullscreenGalleryColorTheme
  Gallery: GalleryItem[]
} & Pick<MediaViewerProps, "isActive" | "deactivate">

export type GalleryItem = {
  title?: string
  text?: string
  Image: JSX.Element
}
