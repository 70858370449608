import tw from "twin.macro"
import { AspectRatioImageWrapper } from "../../elements"

export const CardImage = ({
  children,
  className,
  isPortrait = false,
}: CardImageProps) => {
  const styles = [
    isPortrait
      ? tw`w-2/3 h-0 translate-x-24 -translate-y-32 md:h-96 max-h-96 `
      : tw`aspect-w-16 aspect-h-9`,
  ]
  return (
    <div className={className}>
      <AspectRatioImageWrapper css={styles}>{children}</AspectRatioImageWrapper>
    </div>
  )
}

type CardImageProps = {
  children: JSX.Element
  className?: string
  isPortrait?: boolean
}
