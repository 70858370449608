import React from "react"
import "twin.macro"
import { LabelWrapper } from "./_label-wrapper"
import { FormElement } from "./_types"
import { formElementStyles } from "./_form-element.styles"

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, name, onChange, required }, ref) => {
    return (
      <LabelWrapper {...{ label, name, required }}>
        <textarea
          onChange={onChange}
          tw="h-32 resize-none"
          css={formElementStyles}
          name={name}
          id={name}
          ref={ref}
          required={required}
        />
      </LabelWrapper>
    )
  }
)

export type TextareaProps = FormElement
