import "twin.macro"
import { Link } from "gatsby"
import { ContactForm } from "../../components"

export const GatsbyContactForm = () => {
  return <ContactForm PrivacyLabel={<PrviacyLabel />} />
}

const PrviacyLabel = () => (
  <div>
    Ich habe die{" "}
    <Link
      tw="text-primary-600 underline hover:text-primary transition-colors inline"
      to="/datenschutz"
    >
      Datenschutzerklärung
    </Link>{" "}
    gelesen und akzeptiert.
  </div>
)
