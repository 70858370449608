import tw, { css } from "twin.macro"

const formFocusStyles = tw`focus:(border-gold ring-gold ring-2)`

const formBGStyles = tw`bg-transparent`

export const formElementStyles = css`
  ${[tw`block w-full`, formBGStyles, formFocusStyles]}
`
export const formCheckboxStyles = css`
  ${[formBGStyles, formFocusStyles]}
`
