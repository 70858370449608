import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import slugify from "slugify"
import { SanityCondoPreview } from "./_types"
import { CondoPreview } from "../../components"

export const filterAndSanitizeCondos = (
  condos: SanityCondoPreview[],
  filterString: string
): CondoPreview[] => {
  return condos
    .filter(condo => condo?.referenceNumber?.includes(filterString))
    .map(condo => {
      const slug = slugify(condo.referenceNumber, { lower: true })
      return {
        ...condo,
        Image: (
          <GatsbyImage
            image={condo.titleImage.asset.gatsbyImageData}
            alt={condo.referenceNumber}
          />
        ),
        Link: ({ children }) => <Link to={`/${slug}`}>{children}</Link>,
      }
    })
}
