import "twin.macro"
import { AnimatePresence } from "framer-motion"
import {
  ModalAnimatedContent,
  ModalAnimatedContentProps,
} from "./_modal-animated-content"

export const Modal = ({ isActive, hide, title, text }: ModalProps) => {
  return (
    <AnimatePresence>
      {isActive && <ModalAnimatedContent {...{ hide, title, text }} />}
    </AnimatePresence>
  )
}

export type ModalProps = ModalAnimatedContentProps & {
  isActive: boolean
}
