import React from "react"
import "twin.macro"
import { Title } from "../../elements"

export const TopContent = ({ title, children }: TopContentProps) => {
  return (
    <div tw="container text-center">
      <Title>{title}</Title>
      <div tw="mt-4 max-w-xl mx-auto">{children}</div>
    </div>
  )
}

export type TopContentProps = {
  title: React.ReactNode
  children: React.ReactNode
}
